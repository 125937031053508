import React, { useState, useEffect, useRef } from 'react';
import { useAuth } from '../context/AuthContext';
import './AdventurerDashboard.css';

const AdventurerDashboard = ({ messages, lastSession }) => {
  const { currentUser } = useAuth();
  const [activeTab, setActiveTab] = useState('overview');
  const [avatarUrl, setAvatarUrl] = useState(currentUser?.avatar || '');
  const [isUploading, setIsUploading] = useState(false);
  const [uploadError, setUploadError] = useState('');
  const [expandedMessage, setExpandedMessage] = useState(null);
  
  // Use production URL when in production, localhost for development
  const API_URL = window.location.hostname === 'localhost' 
    ? 'http://localhost:3001/api'
    : 'https://firstveil.com/api';  // Replace with your actual production API URL
  
  // Mock gallery images - will be replaced with actual gallery API
  const galleryImages = [
    { id: 1, url: 'https://via.placeholder.com/300x200/8B0000/FFFFFF?text=Campaign+Image+1', title: 'Battle at Crimson Keep' },
    { id: 2, url: 'https://via.placeholder.com/300x200/000000/FFFFFF?text=Campaign+Image+2', title: 'The Ancient Ruins' },
    { id: 3, url: 'https://via.placeholder.com/300x200/4A0000/FFFFFF?text=Campaign+Image+3', title: 'Meeting the Oracle' },
  ];
  
  const handleAvatarUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;
    
    // Check file size (max 2MB)
    if (file.size > 2 * 1024 * 1024) {
      setUploadError('Image too large. Maximum size is 2MB.');
      return;
    }
    
    // Check file type
    if (!file.type.match('image.*')) {
      setUploadError('Only image files are allowed.');
      return;
    }
    
    setIsUploading(true);
    setUploadError('');
    
    try {
      // Convert file to base64 for demo purposes
      // In production, you would upload to a server
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = async () => {
        const base64Image = reader.result;
        
        // Update user profile with new avatar
        try {
          console.log('Updating avatar at:', `${API_URL}/profile`);
          const response = await fetch(`${API_URL}/profile`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              'x-auth-token': localStorage.getItem('auth_token')
            },
            body: JSON.stringify({
              avatar: base64Image
            })
          });
          
          if (!response.ok) {
            const errorData = await response.json().catch(() => ({}));
            throw new Error(errorData.message || 'Failed to update avatar');
          }
          
          setAvatarUrl(base64Image);
          setUploadError('');
        } catch (err) {
          setUploadError(err.message || 'Failed to update avatar');
          console.error('Error updating avatar:', err);
        }
      };
      
      reader.onerror = () => {
        setUploadError('Error reading file');
      };
    } catch (err) {
      setUploadError(err.message || 'An error occurred during upload');
      console.error('Error uploading avatar:', err);
    } finally {
      setIsUploading(false);
    }
  };
  
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString();
  };
  
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const toggleMessageExpand = (id) => {
    setExpandedMessage(expandedMessage === id ? null : id);
  };
  
  return (
    <div className="adventurer-dashboard">
      <div className="dashboard-tabs">
        <button 
          className={`tab-button ${activeTab === 'overview' ? 'active' : ''}`}
          onClick={() => handleTabClick('overview')}
        >
          Overview
        </button>
        <button 
          className={`tab-button ${activeTab === 'profile' ? 'active' : ''}`}
          onClick={() => handleTabClick('profile')}
        >
          Profile
        </button>
      </div>
      
      <div className="tab-content">
        {activeTab === 'overview' && (
          <div className="overview-tab">
            <div className="gm-messages card-container">
              <h3>Messages from Game Master</h3>
              {messages && messages.length > 0 ? (
                <div className="message-list">
                  {messages.map(message => (
                    <div key={message.id} className={`gm-message ${expandedMessage === message.id ? 'expanded' : ''}`}>
                      <div className="message-header" onClick={() => toggleMessageExpand(message.id)}>
                        <span className="message-from">{message.from}</span>
                        <span className="message-date">{formatDate(message.date)}</span>
                        <span className="expand-icon">{expandedMessage === message.id ? '−' : '+'}</span>
                      </div>
                      <div className="message-preview" onClick={() => toggleMessageExpand(message.id)}>
                        {expandedMessage !== message.id && (
                          <div className="message-snippet">
                            {message.content.replace(/<[^>]*>/g, '').substring(0, 120)}...
                          </div>
                        )}
                      </div>
                      {expandedMessage === message.id && (
                        <div 
                          className="message-content" 
                          dangerouslySetInnerHTML={{ __html: message.content }} 
                        />
                      )}
                    </div>
                  ))}
                </div>
              ) : (
                <p className="no-messages">No messages available.</p>
              )}
            </div>
            
            <div className="last-session card-container">
              <h3>Last Session Recap</h3>
              {lastSession ? (
                <div className="session-recap">{lastSession}</div>
              ) : (
                <p className="no-recap">Coming Soon! Session recaps will be available after your first adventure.</p>
              )}
            </div>
            
            <div className="quick-links card-container">
              <h3>Quick Links</h3>
              <div className="links-grid">
                <a href="http://47.7.21.116:30000/join" target="_blank" rel="noopener noreferrer" className="quick-link foundry">
                  <span className="link-icon">🎲</span>
                  <span className="link-text">FoundryVTT</span>
                </a>
                <a href="https://discord.gg/SGMCCwsd" target="_blank" rel="noopener noreferrer" className="quick-link discord">
                  <span className="link-icon">💬</span>
                  <span className="link-text">Discord Channel</span>
                </a>
                <button onClick={() => handleTabClick('gallery')} className="quick-link gallery">
                  <span className="link-icon">🖼️</span>
                  <span className="link-text">Campaign Gallery</span>
                </button>
              </div>
            </div>
          </div>
        )}
        
        {activeTab === 'profile' && (
          <div className="profile-tab">
            <h3>Your Adventurer Profile</h3>
            
            <div className="avatar-section card-container">
              <div className="current-avatar">
                {avatarUrl ? (
                  <img src={avatarUrl} alt={currentUser.displayName} />
                ) : (
                  <div className="avatar-placeholder">
                    {currentUser.displayName.charAt(0).toUpperCase()}
                  </div>
                )}
              </div>
              
              <div className="avatar-upload">
                <h4>Change Avatar</h4>
                <p>Upload a small image (max 2MB) to represent your character</p>
                
                <label className="upload-button">
                  {isUploading ? 'Uploading...' : 'Choose Image'}
                  <input 
                    type="file" 
                    accept="image/*" 
                    onChange={handleAvatarUpload} 
                    disabled={isUploading}
                  />
                </label>
                
                {uploadError && <div className="upload-error">{uploadError}</div>}
              </div>
            </div>
            
            <div className="profile-info card-container">
              <div className="info-item">
                <div className="info-label">Username</div>
                <div className="info-value">{currentUser.username}</div>
              </div>
              
              <div className="info-item">
                <div className="info-label">Display Name</div>
                <div className="info-value">{currentUser.displayName}</div>
              </div>
              
              <div className="info-item">
                <div className="info-label">Role</div>
                <div className="info-value role-adventurer">Adventurer</div>
              </div>
              
              <div className="info-item">
                <div className="info-label">Member Since</div>
                <div className="info-value">{formatDate(currentUser.createdAt)}</div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AdventurerDashboard; 